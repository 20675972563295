import React from "react";
import { Link } from "gatsby";
import styled from "@emotion/styled";
import { css } from "@emotion/core";
import mq from "@styles/media";
import tw from "twin.macro";

import SEO from "../components/SEO/SEO";
import Layout from "../components/Layout/Layout";
import Transitions from "../components/Transitions";
import Wrapper from "../components/Wrapper";
import Section from "../components/Section";

import NotFoundSVG from "@images/svg/notfound.inline.svg";

const NotFoundPage: React.FC<{}> = () => (
    <Layout>
        <SEO slug="/" noIndex={true} />

        <Transitions.CSS.FadeIn as={Section}>
                <Wrapper>
                    <FlexBox>
                    <Transitions.CSS.FadeInUp as={Box}>
                        <Title><span>404:</span> Not Found</Title>
                        <Text>Oh-oh! You just hit a route that doesn't exist...</Text>
                    </Transitions.CSS.FadeInUp>
                    <Box>
                        <NotFoundIcon />
                    </Box>
                    </FlexBox>
                </Wrapper>
        </Transitions.CSS.FadeIn>
    </Layout>
);

const spanColorAccent = p => css`
    span {
        color: ${p.theme.colors.secondaryAccent};
    }
`;

const Title = styled.div`
    ${tw`mt-12 text-4xl font-semibold leading-none tracking-tight md:mt-0 md:text-6xl`};
    ${spanColorAccent};
`;

const Text = styled.p`
    ${tw`my-8 text-base leading-7 text-left text-gray-700 md:text-lg`};
`;

const NotFoundIcon = styled(NotFoundSVG)`
 ${tw`w-full h-full max-w-full`};
`;

const FlexBox = styled.div`
    ${tw`items-center justify-center flex-1 md:flex`};
`;

const Box = styled.div`
    width: 100%;

    ${mq.from_tablet_ipad`
        width: 70%;
        margin: 20px;
        &:first-of-type {
            margin-left: 0;
        }
    
        &:last-of-type {
            margin-right: 0;
        }
    `};

    ${mq.from_laptop`
        flex: 1 1 0;
        width: 70%;
    `};
`;

export default NotFoundPage;